import React from 'react';

const Modal = ({ type, message }) => {
  const modalClasses = {
    success: 'bg-green-500',
    error: 'bg-red-500',
    info: 'bg-blue-500',
  };

  const modalTypeClass = modalClasses[type] || modalClasses.info;

  return (
    <div className={`text-xl font-medium fixed top-0 left-0 right-0 p-4 ${modalTypeClass} text-white`}>
      {message}
    </div>
  );
};

export default Modal;

import React, { Suspense, lazy } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import SucessModal from "../Components/Model";
AOS.init();

const HeroComponent = lazy(() => import("../Components/HeroComponent"));
// const HeroComponent = React.lazy(() => import("../Components/HeroComponent"));
const Faq = lazy(() => import("../Components/Faq"));
const VideoCarousel = lazy(() => import("../Components/VideoCarousel"));
const Footer = lazy(() => import("../Components/Footer"));
const Cities = lazy(() => import("../Components/Cities"));

export const LandingPage = () => {
  return (
    <div>
      {/* <div className="fixed w-40 h-28 bottom-4 right-4 z-10 rounded-md overflow-hidden lg:w-52 lg:h-28">
        <video
          muted={muted}
          autoPlay
          loop
          controls
          className="w-36 h-28 m-auto rounded-md lg:w-full lg:h-full"
        >
          <source
            src="https://res.cloudinary.com/da05bn5ub/video/upload/v1692890001/one_gd2gii.mp4"
            type="video/mp4"
          />
        </video>
      </div> */}
      <Suspense fallback={<div>Loading...</div>}></Suspense>
      <HeroComponent />
      <div data-aos="fade-up">
        <Cities />
      </div>
      <div data-aos="fade-up">
        <VideoCarousel />
      </div>
      <div data-aos="fade-up">
        <Faq />
      </div>
      <div data-aos="fade-up">
        <Footer />
      </div>
      {/* <AlliedEvent /> */}

    </div>
  );
};
